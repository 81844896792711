// 作曲页背景容器
.poetry-background-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    .poetry-background-container {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/images/UI2/bg@2x.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;

    }
}
@primary-color: #169bd5;