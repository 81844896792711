@page-header-height: 40px;
@base-heading-color: #333;
@component-background: #fff;
@page-title-width: 450px;
@space3x: 24px;

@logo-width: auto;
@logo-height: 48px;

@header-pl: 24px;
@header-pr: 24px;

@header-color: @base-heading-color;
@header-top-color: #fff;
@header-top-bg: transparent;

// mix
.page-title() {
  font-size: 14px;
  color: @base-heading-color;
  font-weight: 500;
}

.pageheader-container {
  position: absolute;
  width: 100%;
  z-index: 999;

  // 顶部提示层
  .page-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: @header-pl;
    color: @header-top-color;
    font-size: 12px;
    background-color: @header-top-bg;
  }

  // 主要内容
  .page-header {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    // height: @page-header-height;
    background-color: transparent;
    padding: 32px 64px;
    flex-direction: column;
    .pageheader-left {
      padding: 18px 25px;
      width: @page-title-width;
      height: @page-header-height;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      // border-right: 1px solid @header-border-color;

      img {
        width: @logo-width;
        height: @logo-height;
        margin-right: 9px;
      }

      .pageheader-title {
        .page-title();
      }
    }

    .pageheader-right {
      color: @header-color;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: @space3x;
      padding-right: @space3x;
      height: 100%;
      margin-top: 22px;
      color: #fff;
      font-size: 18px;

      .ant-btn-link,
      a {
        color: inherit;
        display: flex;
        align-items: center;
      }
    }
  }
}

@primary-color: #169bd5;