.index_page {
  position: relative;
  width: 100%;
  // overflow: hidden;
  overflow-x: hidden;

  .index_banner {
    .index_banner_image1 {
      height: 480px;
      background-image: url('../../assets/images/UI/banner1@2x.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .index_banner_image2 {
      height: 480px;
      background-image: url('../../assets/images/UI/banner2@2x.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .index_content {
    padding-top: 66px;
    height: 600px;
    background-image: url('../../assets/images/UI/底背景@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .works {
      width: 1120px;
      margin: 0 auto;
      position: relative;

      .works_title {
        font-family: STSongti-SC-Black, STSongti-SC;
        position: relative;
        width: 149px;
        height: 30px;

        .title_text {
          position: absolute;
          bottom: 0px;
          font-size: 22px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #313335;
          line-height: 30px;
          letter-spacing: 2px;
          z-index: 2;
        }

        .title_underline {
          position: absolute;
          width: 178px;
          height: 6px;
          bottom: 5px;
          z-index: 1;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url('../../assets/images/UI/装饰暗纹@2x.png');
        }
      }

      .works_list {
        margin-top: 54px;
        display: flex;
        justify-content: space-between;
      }
    }

    .btn_box {
      display: flex;
      justify-content: center;

      .music_creact_btn {
        margin-top: 84px;
        width: 348px;
        height: 63px;
        background-image: url('../../assets/images/UI/立即创作按钮@2x.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        text-align: center;
        line-height: 63px;
        font-size: 18px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #ffffff;

        &:hover {
          background-image: url('../../assets/images/UI/立即创作点击触碰效果.png');
        }
      }
    }
  }

  .poetry-pull-icon-wrapper {
    position: absolute;
    top: 235px;
    right: -140px;
  }
}
@primary-color: #169bd5;