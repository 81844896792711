/*poetry 图标*/
.poetry-icon {
    display: inline-flex;

    width: 14px;
    height: 14px;
    font-size: 14px;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    cursor: pointer;

    &.search {
        &.selected {
            background-image: url(../../assets/images/icons/搜索icon.png);
        }

        background-image: url(../../assets/images/icons/搜索icon.png);
    }




    // &.chart-line {
    //     &.selected {
    //         background-image: url('../pro-icon-chart-line-small-select.png');
    //     }

    //     background-image: url('../pro-icon-chart-line-small-unselect.png');
    // }

    // &.chart-ring {
    //     &.selected {
    //         background-image: url('../pro-icon-chart-ring-small-select.png');
    //     }

    //     background-image: url('../pro-icon-chart-ring-small-unselect.png');
    // }

    // &.chart-table {
    //     &.selected {
    //         background-image: url('../pro-icon-chart-table-small-select.png');
    //     }

    //     background-image: url('../pro-icon-chart-table-small-unselect.png');
    // }

    // &.top-level-1 {
    //     // &.selected {
    //     //   background-image: url('../pro-icon-chart-table-small-select.png');
    //     // }
    //     background-image: url('../pro-icon-top-level-1-small-unselect.png');
    // }

    // &.top-level-2 {
    //     // &.selected {
    //     //   background-image: url('../pro-icon-chart-table-small-select.png');
    //     // }
    //     background-image: url('../pro-icon-top-level-2-small-unselect.png');
    // }

    // &.top-level-3 {
    //     // &.selected {
    //     //   background-image: url('../pro-icon-chart-table-small-select.png');
    //     // }
    //     background-image: url('../pro-icon-top-level-3-small-unselect.png');
    // }

    // &.table-level-2 {
    //     cursor: not-allowed;
    //     opacity: 0.8;
    // }
}
@primary-color: #169bd5;