@scroll-width: 8px;

.scroll-container {

    // 火狐
    scrollbar-color: #dee3eb #e8ecf3;
    /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin;
    /* 滚动条宽度有三种：thin、auto、none */
}

.scroll-container.hover-show-scroll {
    overflow: hidden;
    padding-right: @scroll-width;
}

.scroll-container.hover-show-scroll:hover {
    overflow-y: auto;
    padding-right: 0;
}



/*滚动条样式*/
.scroll-container::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: @scroll-width;
    height: 50px;
    border-bottom-right-radius: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll-container::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border: 2px solid #e8ecf3;
    border-radius: 4px;
    background: #dee3eb;
}

.scroll-container::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    background: #e8ecf3;
}
@primary-color: #169bd5;