.poetry-empty-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-data-icon {
        background-image: url('../../assets/images/empty-data.png');
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;

        width: 160px;
        height: 200px;

        margin-top: 56px;
        margin-bottom: 20px;
    }

    .empty-data-text {
        font-family: STSongti-SC-Black, STSongti-SC;
        font-size: 14px;
        color: #bcc2ce;
    }
}
@primary-color: #169bd5;