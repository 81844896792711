.creact_page {
  height: 100vh;
  padding-top: 100px;
  // background-image: url('../../assets/images/UI2/bg@2x.jpg');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;

  .finish_works {
    position: relative;
    width: 1250px;
    height: 687px;
    margin: 44px auto 0;
    background-image: url('../../assets/images/UI3/底bg@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .left_door {
      width: 350px;
      height: 687px;
      position: absolute;
      top: 0;
      left: 300px;
      background-image: url('../../assets/images/UI3/左门@2x.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transition: all 1s;
      z-index: 2;
    }

    .left_door_open {
      left: 0;
    }

    .finish-image {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .right_door {
      width: 350px;
      height: 687px;
      position: absolute;
      top: 0;
      right: 300px;
      background-image: url('../../assets/images/UI3/右门@2x.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transition: all 1s;
      z-index: 2;
    }

    .right_door_open {
      right: 0;
    }

    .poetry {
      margin-top: 165px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .play_control {
      left: 50%;
      transform: translateX(-50%);
    }

    .btns_list {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      width: 520px;
      display: flex;
      justify-content: space-between;

      .bottom_btn {
        display: inline-block;
        width: 132px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-image: url(../../assets/images/UI-create2/按钮1@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        user-select: none;

        font-size: 14px;
        font-weight: 900;
        font-family: STSongti-SC-Black, STSongti-SC;

        // poetry-btn hover
        &:hover {
          background-image: url(../../assets/images/UI-create2/按钮1@2x-hover.png);
        }
      }
    }
  }
}
@primary-color: #169bd5;