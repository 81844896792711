.top_banner {
  @step-line-height: 62px;
  @text-line-height: 40px;

  height: @step-line-height + @text-line-height;

  .step-line {
    position: relative;

    height: @step-line-height;
    width: 774px;
    margin: 0 auto;

    background-repeat: no-repeat;
    background-size: cover;
  }

  .step_text_container {

    margin: 18px auto 0;
    padding: 0 14px;
  }

  .step_content {
    font-size: 16px;
    font-family: STSongti-SC-Black, STSongti-SC;
    font-weight: 900;
    color: #333333;
    line-height: 22px;

  }

  .step_1 {
    background-image: url('../../assets/images/UI2/步骤1@2x.png');
  }

  .step_2 {
    background-image: url('../../assets/images/UI2/步骤2@2x.png');
  }

  .step_3 {
    background-image: url('../../assets/images/UI2/步骤3@2x.png');
  }



  .text-line {
    height: @text-line-height;
  }


  .step_text_container {
    width: 774px;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
  }



  .back_btn {
    position: absolute;
    left: -139px;
    top: 18px;

    width: 24px;
    height: 39px;
    margin-right: 139px;
    background-image: url('../../assets/images/UI2/返回icon@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }

}
@primary-color: #169bd5;