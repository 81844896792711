.play_control {
  position: absolute;
  bottom: 128px;
  display: flex;
  justify-content: space-between;
  .play_btn,
  .pause_btn {
    width: 43px;
    height: 42px;
    margin-right: 39px;

    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .play_btn {
    background-image: url("../../assets/images/UI/播放@2x.png");
  }
  .pause_btn {
    background-image: url("../../assets/images/UI/暂停@2x.png");
  }

  .progress {
    .progress_line {
      width: 438px;
      .ant-slider {
        margin-bottom: 0;
        .ant-slider-track {
          background-color: #4b6087;
        }
        .ant-slider-handle {
          background-color: #4b6087;
          border: none;
        }
      }
    }
    .progress_time {
      display: flex;
      justify-content: space-between;
      padding: 0 5px;
      span {
        font-size: 12px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #333333;
      }
    }
  }
}

@primary-color: #169bd5;