.ant-drawer-content-wrapper {
  // width: 785px !important;
  .ant-drawer-body {
    display: flex;
    justify-content: center;
    background-image: url("../../assets/images/UI/右浮窗背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .close_btn {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-24px);
      width: 30px;
      height: 48px;
      background-image: url("../../assets/images/UI/返回icon@2x.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}

@primary-color: #169bd5;