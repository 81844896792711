@sigle-word-width: 23px;

.poetry {
  margin-top: 284px;
  min-width: @sigle-word-width * 10;
  max-width: @sigle-word-width * 10;

  .poetry_title {
    text-align: center;
    font-size: 18px;
    font-family: STSongti-SC-Black, STSongti-SC;
    font-weight: 900;
    color: #333333;
  }

  .poetry_author {
    padding-right: 24px;
    text-align: right;
    font-size: 12px;
    font-family: STSongti-SC-Black, STSongti-SC;
    font-weight: 900;
    color: #8a8a8a;
  }

  .poetry_content {
    height: 420px;
    // padding-left: 32px;
    font-size: 14px;
    font-family: STSongti-SC-Black, STSongti-SC;
    font-weight: 900;
    color: #333333;
    line-height: 48px;
    letter-spacing: 9px;
    overflow: hidden;

    // padding-right: 10px;

    p {
      margin: 0;
    }

    // &:hover{
    //   overflow-y: auto;
    //   padding-right: 0;
    // }
  }

  // /*滚动条样式*/
  // .poetry_content::-webkit-scrollbar {
  //   /*滚动条整体样式*/
  //   width: 10px;
  //   border-bottom-right-radius: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  // }
  // .poetry_content::-webkit-scrollbar-thumb {
  //   /*滚动条里面小方块*/
  //   border: 2px solid #19202e;
  //   border-radius: 4px;
  //   background: #606774;
  // }
  // .poetry_content::-webkit-scrollbar-track {
  //   /*滚动条里面轨道*/
  //   background: #19202e;
  // }
}
@primary-color: #169bd5;