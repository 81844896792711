.poetry-pull-icon-wrapper {
    &.hidden {
        display: none;
    }

    .poetry-pull-icon {
        display: inline-block;
        width: 47px;
        height: 87.1px;

        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../../assets/images/pull-icon.gif');

        cursor: pointer;
    }
}
@primary-color: #169bd5;