.select_poetry {
  margin: 44px auto 0;
  padding: 113px 145px 0;
  width: 1250px;
  height: 687px;
  background-image: url('../../assets/images/UI2/矩形@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .search_box {
    width: 100%;
    height: 38px;
    background: #ffffff;
    // box-shadow: 0px 1px 6px 0px rgba(149, 163, 196, 0.5) inset;
    border-radius: 3px;
    padding: 8px 16px;
    border: none;

    background-image: url('../../assets/images/select-input2.png');
    background-repeat: no-repeat;
    background-size: cover;


    .ant-input-prefix {
      margin-right: 16px;
      color: rgba(149, 163, 196, 0.5);
    }

    .ant-input {
      width: 100%;
      height: 20px;
      line-height: 20px;
      background: transparent;
      font-size: 14px;
      font-family: STSongti-SC-Black, STSongti-SC;
      font-weight: 900;


      &::placeholder {
        color: #BCC2CE;
      }
    }

    .ant-input-wrapper {
      // .ant-input-affix-wrapper {
      //   background-image: url('../../assets/images/UI2/搜索框@2x.png');
      //   background-repeat: no-repeat;
      //   background-size: 100% 100%;

      //   input {
      //     background: rgba(0, 0, 0, 0);
      //   }
      // }
    }
  }

  // 下面表格


  .ant-table-wrapper {

    @thPadding: 20px;
    @tdPadding: 15px;
    @margin: 64px - @thPadding;
    margin-top: @margin;

    .ant-table {
      margin-bottom: 91px - @tdPadding;
    }


    tr.ant-table-row {
      cursor: pointer;

      &:hover {
        .table_body {
          color: #345381;
        }
      }
    }

    .ant-table-container table>thead>tr>th {
      padding: @thPadding 0 @thPadding + 5;
    }

    .ant-table-container table>tbody>tr>td {
      padding: @tdPadding 0;
    }

    // 表格头部默认背景色去掉
    .ant-table-thead>tr>th {
      font-size: 16px;
      font-family: STSongti-SC-Black, STSongti-SC;
      font-weight: 900;
      color: #B4BED0;
      line-height: 22px;

      background-color: transparent;

      border: none;
    }

    .ant-table-tbody>tr>td {
      border: none;
      font-size: 14px;
      font-family: STSongti-SC-Black, STSongti-SC;
      font-weight: 900;
      color: #333333;
      line-height: 20px;

    }

    // 悬浮背景色
    .ant-table-tbody>tr.ant-table-row:hover>td {
      background-color: transparent;
    }

  }

  // 分页
  .ant-pagination {
    @pagination-item-border: 1px solid #B4BED0;
    @pagination-item-radius: 3px;
    margin: 0;

    // 一些宽度和高度
    .ant-pagination-item,
    .ant-pagination-item-container,
    .ant-pagination-prev,
    .ant-pagination-next {

      min-width: 24px;
      width: 24px;
      height: 24px;
      line-height: 24px;
    }

    .ant-pagination-item {
      border-radius: @pagination-item-radius;
      // border: @pagination-item-border;
      border-color: #B4BED0;
      overflow: hidden;

      a {

        width: 100%;
        height: 100%;
        line-height: 23px;
        margin: 0;
        padding: 0;

        font-size: 12px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #B4BED0;
      }
    }

    .ant-pagination-item-active {
      background: #667BA1;
      border-color: #667BA1;

      a {
        color: #fff;
      }

    }

    .ant-pagination-jump-next {
      min-width: 24px;
      width: 24px;
      height: 24px;
      line-height: 24px;

    }

    // 上一页和下一页
    .ant-pagination-prev {
      margin-right: 14px;
    }

    .ant-pagination-next {
      margin-left: 14px - 8px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      width: 78px;
      border-radius: @pagination-item-radius;
      border: @pagination-item-border;
      overflow: hidden;

      .ant-pagination-item-link {
        border: none;
        font-size: 12px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #B4BED0;
        line-height: 17px;
      }

    }

  }

  //   .ant-table-thead>tr>th {
  //     border: none;
  //   }

  //   .ant-table-tbody>tr>td {
  //     border: none;
  //   }

  //   .table_header {
  //     font-size: 16px;
  //     font-family: STSongti-SC-Black, STSongti-SC;
  //     font-weight: 900;
  //     color: #b4bed0;
  //   }

  //   .table_body {
  //     font-size: 14px;
  //     font-family: STSongti-SC-Black, STSongti-SC;
  //     font-weight: 900;
  //     color: #333333;
  //   }

  .table_title,
  .table_author {
    display: block;
    width: 88px;
    overflow: hidden;
    /*超出部分隐藏*/
    white-space: nowrap;
    /*禁止换行*/
    text-overflow: ellipsis;
  }

  .table_paragraphs {
    display: block;
    width: 720px;
    overflow: hidden;
    /*超出部分隐藏*/
    white-space: nowrap;
    /*禁止换行*/
    text-overflow: ellipsis;
  }




  //   .ant-table-wrapper {
  //     .ant-pagination {
  //       position: absolute;

  //       .ant-pagination-item {
  //         border-color: #345381;

  //         &:hover {
  //           a {
  //             color: #345381;
  //           }
  //         }
  //       }

  //       .ant-pagination-item-active {
  //         background: #345381;
  //         color: #fff;

  //         a {
  //           color: #fff !important;
  //         }
  //       }
  //     }
  //   }
  // }

  // .ant-table-row {
  //   background: #fff !important;
  // }
}
@primary-color: #169bd5;