.works_list_item {
  .music_box {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 72px;
    margin-bottom: 18px;
    width: 175px;
    height: 189px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .music_listen {
      position: absolute;
      top: 18px;
      right: -6px;
      width: 100px;
      height: 38px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/UI/播放量@2x.png");
      .music_listen_number {
        line-height: 36px;
        margin-left: 20px;
        font-size: 12px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #425069;
      }
    }
    .music_play_btn {
      width: 47px;
      height: 46px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/UI/播放按钮@2x.png");
      cursor: pointer;
    }
  }
  .music_box1 {
    background-image: url("../../assets/images/UI/位图1@2x.png");
  }
  .music_box2 {
    background-image: url("../../assets/images/UI/位图2@2x.png");
  }
  .music_box3 {
    background-image: url("../../assets/images/UI/位图3@2x.png");
  }
  .music_box4 {
    background-image: url("../../assets/images/UI/位图4.png");
  }
  .music_title {
    font-size: 14px;
    font-family: STSongti-SC-Black, STSongti-SC;
    font-weight: 900;
    color: #333333;
  }
  .music_footer {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    .title_bottom_border {
      width: 42px;
      height: 2px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/UI/装饰线@2x.png");
    }
  }
}

@primary-color: #169bd5;